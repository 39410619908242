var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"show":_vm.showModal,"modal-classes":"elite-modal add-file","size":"lg"}},[_c('div',[_c('el-form',{ref:"contractFileForm",attrs:{"model":_vm.contractFile,"label-position":"top"},on:{"submit":function($event){$event.preventDefault();return _vm.checkContractInformation.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('el-form-item',{staticClass:"form-group-wrapper-item w-100 mb-3",attrs:{"prop":"excerpt"}},[_c('el-checkbox',{attrs:{"border":""},model:{value:(_vm.contractFile.is_template),callback:function ($$v) {_vm.$set(_vm.contractFile, "is_template", $$v)},expression:"contractFile.is_template"}},[_vm._v(" "+_vm._s(_vm.$t("CONTRACTS.ADD_TEMPLATE_DOCUMENT"))+" ")])],1),(!_vm.contractFile.is_template)?_c('dropzone-file-upload',{key:_vm.renderKey,attrs:{"url":_vm.uploadUrl,"multiple":""},on:{"error":_vm.onUploadError},model:{value:(_vm.filesModel),callback:function ($$v) {_vm.filesModel=$$v},expression:"filesModel"}}):_vm._e(),(_vm.filesModel.length > 0 || _vm.contractFile.is_template)?_c('div',[_c('el-form-item',{staticClass:"form-group-wrapper-item w-100",attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"prop":"name","rules":[
            {
              required: true,
              message: _vm.$t('CONTRACTS.NAME_REQUIRED'),
            },
          ]}},[_c('base-input',{staticClass:"form-wrapper"},[_c('el-input',{attrs:{"placeholder":_vm.$t('COMMON.NAME')},model:{value:(_vm.contractFile.name),callback:function ($$v) {_vm.$set(_vm.contractFile, "name", $$v)},expression:"contractFile.name"}})],1)],1),(_vm.contractFile.is_template)?_c('el-form-item',{staticClass:"form-group-wrapper-item w-100",attrs:{"label":`${_vm.$t('CONTRACTS.TEMPLATE_NAME')} (*)`,"prop":"template_name","rules":[
            {
              required: true,
              message: _vm.$t('CONTRACTS.TEMPLATE_NAME_REQUIRED'),
            },
          ]}},[_c('base-input',{staticClass:"form-wrapper"},[_c('el-input',{attrs:{"placeholder":_vm.$t('CONTRACTS.TEMPLATE_NAME')},model:{value:(_vm.contractFile.template_name),callback:function ($$v) {_vm.$set(_vm.contractFile, "template_name", $$v)},expression:"contractFile.template_name"}})],1)],1):_vm._e(),_c('el-form-item',{staticClass:"form-group-wrapper-item w-100",attrs:{"label":`${_vm.$t('CONTRACTS.SIGNATURE_TYPE')} (*)`,"prop":"signature_type","rules":[
            {
              required: true,
              message: _vm.$t('CONTRACTS.SIGNATURE_TYPE_REQUIRED'),
            },
          ]}},[_c('base-input',{staticClass:"form-wrapper"},[_c('el-select',{attrs:{"label":_vm.$t('CONTRACTS.SIGNATURE_TYPE'),"placeholder":_vm.$t('CONTRACTS.SIGNATURE_TYPE')},model:{value:(_vm.contractFile.signature_type),callback:function ($$v) {_vm.$set(_vm.contractFile, "signature_type", $$v)},expression:"contractFile.signature_type"}},_vm._l((_vm.signatureTypes),function(type,val){return _c('el-option',{key:val,attrs:{"value":val,"label":`${val ? _vm.$t('CONTRACTS.' + val) : ''}`}})}),1)],1)],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.contractFile.signature_type!=_vm.SUBTYPE_SEAL),expression:"contractFile.signature_type!=SUBTYPE_SEAL"}],staticClass:"form-group-wrapper-item w-100",attrs:{"label":`${_vm.$t('CONTRACTS.SIGNATURE_PAGE')} (*)`,"prop":"signature_page","rules":[
            {
              required: true,
              message: _vm.$t('CONTRACTS.SIGNATURE_PAGE_REQUIRED'),
            },
          ]}},[_c('base-input',{staticClass:"form-wrapper"},[_c('el-input',{attrs:{"placeholder":_vm.$t('CONTRACTS.SIGNATURE_PAGE')},model:{value:(_vm.contractFile.signature_page),callback:function ($$v) {_vm.$set(_vm.contractFile, "signature_page", $$v)},expression:"contractFile.signature_page"}})],1)],1),_c('el-form-item',{staticClass:"form-group-wrapper-item w-100",attrs:{"label":`${_vm.$t('COMMON.EXCERPT')}`,"prop":"excerpt"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 3, maxRows: 4 },"placeholder":_vm.$t('COMMON.EXCERPT')},model:{value:(_vm.contractFile.excerpt),callback:function ($$v) {_vm.$set(_vm.contractFile, "excerpt", $$v)},expression:"contractFile.excerpt"}})],1)],1):_vm._e()],1)],1),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn add",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.checkContractInformation}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("CONTRACTS.ADD_FILES"))+" ")]),_c('button',{staticClass:"btn close btn-link ml-auto",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }