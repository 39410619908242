export default {
  type: "contracts",
  contract_type: null,
  undetermined:false,
  duration:null,
  name: null,
  slug: null,
  excerpt: null,
  meta: null,
  relationshipNames: ["files"],
  files: [],
};
